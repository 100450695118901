import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import PropTypes from 'prop-types';
import Chart from './Chart.js';
import helper from './helper.js';
import i18n from './i18n.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function PrintCharts(props) {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

	const gs = props.gs;
	let chartComponents = [];
  let tabComponents = [];
  let chartGroups = [];

	let results = [];

	let cs = gs.game.currentStep - 1;
  let maxRounds = gs.game.stepsNumber;
	if (props.step !== null && props.step >= 0)
		cs = props.step * 1;
    
	let plId = gs.playerStatuses[0].player.id;
	if (props.playerId !== null && props.playerId >= 0)
		plId = props.playerId * 1;

    for (let i = 0; i <= Math.min(cs, Object.keys(gs.game.results).length); i++) {
      try {
        results.push(JSON.parse(gs.game.results[i]));
      } catch (e) 
      {

      }
    }

    if (results.length < cs + 1) return;

    if (!results[cs] || results[cs].charts === undefined) return;
    
    let rch = results[cs].charts;

    let fn = (x) => {
      return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    // if there're some charts
    if (rch) {
      for (let c = 0; c < rch.length; c++) {
        let chart = rch[c];
        // take next chart description
        let chartData = [];
        
        let chartComponent = null;

        if (chart.group) {
          // chartGroups.push(
          //     <Link key={"chartGrpA_" + c} href={"#chartGrp_" + c}>
          //       <Typography  sx={{mr: 2}} variant="button">
          //         {chart.group + ""}
          //       </Typography>
          //     </Link>);
          chartComponent = (
              <Box key={"chartGrp_" + c} sx={{pt: 3}}>
                  {/*<a id={"chartGrp_" + c} />*/}
                  <Typography variant="button">{chart.group + ""}</Typography>
                  <Typography sx={{pt: 3}}>{chart.description + ""}</Typography>
              </Box>);

          tabComponents.push(<Tab key={'tabkey_'+c} label={chart.group} {...a11yProps(c)} sx={{mt: c>0?3:0, alignItems: "flex-start", textAlign: "left"}} />);
          chartComponents.push(<TabPanel key={'tabpanel_'+c} index={c} value={tab}>{chartComponent}</TabPanel>);
          continue;
        } 

        if (chart.scope === "players") {
          // сравнение игроков на пузырьковой диаграмме
            for (let i = 0; i < gs.game.playersNumber; i++) {
                chartData.push({
                  x : results[cs].results[i][chart.x], 
                  y : results[cs].results[i][chart.y],
                  z : results[cs].results[i][chart.z],
                  label: {
                    tooltip : results[cs].results[i].playerName + " " + chart.zTitle + ": " + results[cs].results[i][chart.z],
                    color : helper.getColorByIndex(results[cs].results[i].playerId)
                  }
                });
            }
            chartComponent = (
              <Chart key={"chart_" + c}
                type="scatter"
                xname={chart.xTitle} 
                yname={chart.yTitle} 
                zname={chart.zTitle}
                name={chart.title}
                data={chartData}/>);               
        } else
            // изменение какого-то значения со временем у одного игрока
            if (chart.scope === "time" && plId !== null && plId >= 0) {
                  let cy = [];
                  if (Array.isArray(chart.y))
                    cy = chart.y
                  else
                    cy = [chart.y];
                  for (let step = 0; step <= cs; step++) {
                    for (let i = 0; i < gs.game.playersNumber; i++) {
                      if (results[step].results[i].playerId === plId) {
                          let cd = {
                                      x : i18n.t("lStep") + " " + (step + 1) 
                                   };
                          let tt = "";
                          for (let cyi = 0; cyi < cy.length; cyi++) {
                            let arr = cy[cyi].split(".");
                            let r = 0;
                            if (arr.length === 1)
                              r = results[step].results[i][cy[cyi]];
                            else
                              r = results[step].results[i][arr[0]][arr[1]];
                            cd[cy[cyi]] = r;

                            //cd[cy[cyi]] = results[step].results[i][cy[cyi]];
                            if (chart.yTitle && Array.isArray(chart.yTitle))
                                tt += "\n" + chart.yTitle[cyi] + ": " + cd[cy[cyi]];
                            else
                                tt = "\n" + chart.yTitle + ": " + cd[cy[cyi]];
                          };
                          cd.label = {tooltip : tt};
                          //cd.color = helper.getColorByIndex(results[step].results[i].playerId);
                          chartData.push(cd);
                          break;
                      }
                    }
                  }
                  chartComponent = (
                    <Chart key={"chart_" + c}
                      type={(chart.type && chart.type !== "")?chart.type:"line"}
                      xname={chart.xTitle} 
                      yname={chart.yTitle} 
                      name={chart.title}
                      colorScheme={chart.colorScheme}
                      color={"#8D805B"}
                      yFields={chart.y}
                      data={chartData}
                      rounds={cs}
                      maxRounds={maxRounds}/>);
            } else 
            if (chart.scope === "radar" && plId !== null && plId >= 0) {
                  let cr = chart.r; // должен быть массивом
                  for (let i = 0; i < gs.game.playersNumber; i++) {
                    if (results[cs].results[i].playerId === plId) {
                        for (let cri = 0; cri < cr.length; cri++) {
                          let cd = {};
                          let tt = "";
                          cd["key"] = chart.rTitle[cri];
                          cd["value"] = results[cs].results[i][cr[cri]];
                          tt += "\n" + chart.rTitle[cri] + ": " + cd[cr[cri]];
                          cd.label = {tooltip : tt};
                          cd.color = helper.getColorByIndex(results[cs].results[i].playerId);
                          chartData.push(cd);
                        };
                        break;
                    }
                  }
                  chartComponent = (
                    <Chart key={"chart_" + c}
                      type={"radar"}
                      rname={chart.rTitle} 
                      name={chart.title}
                      color={"#8D805B"}
                      rFields={chart.r}
                      colorScheme={chart.colorScheme}
                      data={chartData}/>);
            } else
            if (chart.scope === "budget" && plId !== null && plId >= 0) {
                  let cin = chart.income; 
                  let cout = chart.expenses;
                  let colors = [];
                  let colorIn = helper.getColorByIndex2(chart.colorScheme, 0);
                  let colorOut = helper.getColorByIndex2(chart.colorScheme, 4);
                  let proficite = 0;
                  let deficite = 0;

                  for (let i = 0; i < gs.game.playersNumber; i++) {
                    if (results[cs].results[i].playerId === plId) {
                        //chartData = [["From", "To", "Weight"]];
                        chartData = [[
                          {type:'string', role:'domain', label: 'From'},
                          {type:'string', role:'domain', label: 'To'},
                          {type:'number', role:'data', label: 'Weight'},
                          {type:'string', role:'tooltip', label: 'Tooltip'}
                        ]];
                        for (let cri = 0; cri < cin.length; cri++) {
                          let cinD = cin[cri].split(".");
                          let r = 0;
                          if (cinD.length === 1)
                            r = results[cs].results[i][cin[cri]];
                          else
                            r = results[cs].results[i][cinD[0]][cinD[1]];

                          let d = r;
                          if (d <= 0) {
                            proficite -= d;
                            chartData.push([chart.incomeTitle[cri] + ": " + fn(d), " ", 0, fn(d)]);
                          } else {
                            chartData.push([chart.incomeTitle[cri] + ": " + fn(d), " ", d, fn(d)]);
                          }
                        };
                        colors.push(colorIn);
                        
                        for (let cri = 0; cri < cout.length; cri++) {
                          let coutD = cout[cri].split(".");
                          let r = 0;
                          if (coutD.length === 1)
                            r = results[cs].results[i][cout[cri]];
                          else
                            r = results[cs].results[i][coutD[0]][coutD[1]];

                          let d = r;
                          if (d <= 0) {
                            deficite -= d;
                            chartData.push([" ", chart.expensesTitle[cri] + ": " + fn(d), 0, fn(d)]);
                          } else {
                            chartData.push([" ", chart.expensesTitle[cri] + ": " + fn(d), d, fn(d)]);
                          }
                          colors.push(colorOut);
                        };
                        break;
                    }
                  }

                  if (deficite - proficite > 0)
                    chartData.push([i18n.t("tDeficite") + ": " + fn(deficite - proficite), " ", deficite - proficite, fn(deficite - proficite)]);
                  else
                    if (proficite - deficite > 0)
                      chartData.push([" ", i18n.t("tProficite") + ": " + fn(proficite - deficite), proficite - deficite, fn(proficite - deficite)]);

                  chartComponent = (
                    <Chart key={"chart_" + c}
                      type={"budget"}
                      rname={chart.rTitle} 
                      name={chart.title}
                      color={"#8D805B"}
                      rFields={chart.r}
                      colorScheme={chart.colorScheme}
                      colors={colors}
                      data={chartData}/>);
            } else
            // изменение какого-то значения со временем у всех игроков
            if (chart.scope === "generalTime") {
              let cy = [];
              if (Array.isArray(chart.y))
                cy = chart.y
              else
                cy = [chart.y];
              for (let step = 0; step <= cs; step++) {
                let cd = {
                            x : i18n.t("lStep") + " " + (step + 1) 
                         };
                let tt = "";
                for (let cyi = 0; cyi < cy.length; cyi++) {
                  let yAxis = cy[cyi].split(".");
                  let r = 0;
                  if (yAxis.length === 1)
                    r = results[step][chart.y];
                  else
                    r = results[step][yAxis[0]][yAxis[1]];
                  cd[cy[cyi]] = r;

                  if (chart.yLabel && Array.isArray(chart.yLabel))
                      tt += "\n" + chart.yLabel[cyi] + ": " + r;
                  else
                      tt = "\n" + chart.yLabel + ": " + r;
                };
                cd.label = {tooltip : tt};
                chartData.push(cd);
              }
              chartComponent = (
                <Chart key={"chart_" + c}
                  type={(chart.type && chart.type !== "")?chart.type:"line"}
                  xname={chart.xTitle} 
                  yname={chart.yTitle} 
                  name={chart.title}
                  yFields={chart.y}
                  colorScheme={chart.colorScheme}
                  color={"#8D805B"}
                  data={chartData}
                  rounds={cs}
                  maxRounds={maxRounds}/>);
            }

        tabComponents.push(<Tab key={'tabkey_'+c} label={chart.title} {...a11yProps(c)} sx={{m: 0, ml: 1, alignItems: "flex-start", textAlign: "left", textTransform: "none"}} />);
        chartComponents.push(<TabPanel key={'tabpanel_'+c} index={c} value={tab}>{chartComponent}</TabPanel>);
      }
    }

    return (
    	<>
        {/*<a id={"reportsTop"}/>*/}
        <Box sx={{marginTop: 10}}>
      		<Typography variant="button">
      			{i18n.t("tCharts")}
      		</Typography>
        </Box>
        {chartGroups}
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 500, width : "100%" }}>
          <Tabs value={tab} onChange={handleTabChange} variant="scrollable" 
                scrollButtons orientation="vertical" sx={{ borderRight: 1, borderColor: 'divider', minWidth: 200, maxWidth : 220 }}>
            {tabComponents}
          </Tabs>
          <Box sx={{flexGrow :1}}>
            {chartComponents}
          </Box>
        </Box>
    	</>
    );
}