//https://javascript.info/promise-api
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import i18n from './i18n.js';

const urlConfig = 
	{
		dev: "http://localhost:8080/",
		prod: "https://play.y42group.com:8443/",
		prod2: "https://play.sim4ny.com:8443/"	
	};

const logoConfig = {
		dev: 'Dev',
		prod: 'Y42',
		prod2: 'Sim4ny'
	}

const langsConfig = {
		dev: ['ru', 'en'],
		prod: ['ru', 'en'],
		prod2: ['en']
	}


var url = urlConfig.dev;
var logo = logoConfig.dev;
var langsAvailable = langsConfig.dev;

if (window.location.host.includes("play.y42group.com")) {
	url = urlConfig.prod;
	logo = logoConfig.prod;
	langsAvailable = langsConfig.prod;
}

if (window.location.host.includes("play.sim4ny.com")) {
	url = urlConfig.prod2;
	logo = logoConfig.prod2;
	langsAvailable = langsConfig.prod2;
}

class y42 {
	ok = 200;

	api = (method, func, urlparams, body, headers) => {
		if (headers == null)
			headers = new Headers();
		headers.set('Content-Type', 'application/json;charset=utf-8');

		let authBase64 = sessionStorage.getItem('authBase64');

		if (authBase64 && !headers.has('Authorization') && !(method === "auth"))
			headers.set('Authorization', authBase64);
		
		let options = {
			method: method,
			headers: headers
		};

		if (body !== null && body !== undefined)
			options.body = body;

		let prm = "";
		if (urlparams !== null && urlparams !== undefined)
			prm = "/" + urlparams;

	  	return fetch(url + func + prm, options)
	  	  .then((res) => {
			  	if (!res.ok) {
			  		if (!res.json)
			  		  return {_status: res.status, message: ""};
			  	} else
			  		if (method.toLowerCase() === "delete")
			  			return {_status: res.status, message: ""};
			  	try {		  		
			  		let json = res.json();
			  		return json;
			  	} catch (e) {
			  		return {_status: res.status, message : ""};
			  	}
			})
	  	  .then((res) => {
	  	  	let message = res.message;
	  	  	if (res.status && res.status !== this.ok) {
	  	  		if (res.substatus === "timeout")
	  	  			window.location = "/refresh";
				else
					if (res.substatus && res.substatus === "unauthorized")
						message = this.getMessage(401);
	  	  		return {_status : res.status, message : message};
	  	  	} else
		  	  	if (res && !res._status)
					res._status = this.ok;
	  	  	return res;
	  	  })
	  	  .catch(error => {
		  	if (method.toLowerCase() === "delete")
		  	    return {_status: 409, message: "Error deleting the record"};
		  	if (func.toLowerCase() === "auth")
		  		return {_status: 401, message: this.getMessage(401)};
	  	  	let json = {_status: 999};
	  	  	return json;
	  	  });
	}

	get = (func, urlparams) => {
		return this.api("GET", func, urlparams, null, null);
	}

	put = (func, urlparams, body) => {
		return this.api("PUT", func, urlparams, body, null);
	}

	post = (func, urlparams, body) => {
		return this.api("POST", func, urlparams, body, null);
	}

	del = (func, urlparams) => {
		return this.api("DELETE", func, urlparams, null, null);
	}

	getUser = () => {
		let su = sessionStorage.getItem('user');
		if (su && su != null) {
			try {
				return JSON.parse(su);
			} catch (error) {
				return {};
			}
		}
		return {};
	}

	setUser = (user) => {
		sessionStorage.setItem('user', JSON.stringify(user));
	}

	login = async (userName, password) => {
		sessionStorage.removeItem('user');
	  	//let authString = userName + ":" + password;
	  	let headers = new Headers();
	  	let authBase64 = "";
	  	let res = {};

	  	/*
	  	try {
		  	authBase64 = 'Basic ' + btoa(authString);
	  	} catch (error) {
	  		res._status = 990;
	  		return res;
	  	}

	  	headers.set('Authorization', authBase64);
	  	*/

  		sessionStorage.setItem('authBase64', null);
  		sessionStorage.setItem('user', null);

	  	//res = await this.api("get", "login", null, null, headers);
	  	res = await this.api("post", "auth", null, '{ "username" : "' + userName + '", "password" : "' + password + '" }', headers);
		if (res._status === this.ok) {
			authBase64 = "Bearer " + res.token;
			sessionStorage.setItem('authBase64', authBase64);
			let usr = await this.get("currentUser");
			if (usr._status === this.ok) {
		  		let acc = await this.get("accounts", usr.account)
		  		let accname = "";
		  		let acccode = "";
		  		let acctermdate = new Date();
		  		let accmaxplayers = 0;
		  		if (acc._status === this.ok) {
		 			accname = acc.name;
		 			acccode = acc.code
		 			acctermdate = acc.terminationDate;
		 			accmaxplayers = acc.maxPlayers;
		  		}
				let user = {
					name: usr.name,
					fullName: usr.fullName,
					role: usr.role,
					id: usr.id,
					account: {
						id : usr.account, 
						name : accname,
						code : acccode,
						termDate : acctermdate,
						maxPlayers : accmaxplayers
					}
				}
				this.setUser(user);
			}
	  	} else {};
	  	return res;
	}

	logout = async () => {
	  	await this.get("logout", "");

		sessionStorage.removeItem('user');
  		sessionStorage.setItem('authBase64', null);
  		
	  	return null;
	}

	sendAction = async (action, playerId, step, data, comment) => {
		let obj = {
			action: action,
			player: {
				id: playerId
			},
			step: step,
			data: data,
			comment: comment
		}
		return this.post("actions", "", JSON.stringify(obj));
	}

	queryActions = async(query) => {
		const res = await this.get("actions/query", query);
		if (res._status !== this.ok) {
			return 0;
		} else
			return res;
	}

	getActionsByQueries = async(queries) => {
		const res = await this.post("actions/queries", "", JSON.stringify(queries));
		if (res._status !== this.ok) {
			return 0;
		} else
			return res;
	}

	getMessage = (status, message) => {
		if (message && message !== "" && message !== null)
			return message;
		else
			switch (status) {
				case this.ok: return i18n.t("OK");
				case 999: return i18n.t("errNoConnection");
				case 401: return i18n.t("errAccessDenied");
				case 403: return i18n.t("errOperationUnavailable");
				case 404: return i18n.t("errOperationNotFound");
				case 500: return i18n.t("errInternalServerError");
				case 990: return i18n.t("errUseLatingLettersInPassword"); 
				default: 
					return i18n.t("errError") + ": " + status;
			}
	}

	playSound = (sound) => {
		return new Promise(function(resolve, reject) {
			const audio = new Audio(sound);
			audio.load();
			audio.onerror = reject;
			audio.onended = resolve;
			audio.volume = 0.05;
			audio.play();			
		});
	}

	getServiceUrl = () => {
		return url;
	}

	subscribeWS = (topic, callback) => {
        const client = new Client({
            webSocketFactory: () => new SockJS(url + "ws"),
            onConnect: () => {
                client.subscribe(topic, callback);
            },
            onDisconnect: () => {
            }
        });
        return client;
	}


	getSiteUrl = () => {
		const s = window.location.href.split("/");
  		return s[0] + "/" + s[1] + "/" + s[2];
	}

	getSiteName = () => {
		return logo;
	}

	getAvailableLangs = () => {
		return langsAvailable;
	}

	getVersion = () => {
		return "20241209.1";
	}
}

export default new y42();