import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Typography, Button, Dialog, Grid, Tabs, Tab, Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Snackbar, Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';
import MoveForm from './MoveForm.js';
import LittleCard from './LittleCard.js';
import Countdown2 from './Countdown2.js';
//import Timer from './Timer.js';
import CodeEditor from './CodeEditor.js';
import PrintCharts from './PrintCharts.js';
import { FilePicker } from 'react-file-picker';
import helper from './helper.js';
import Loading from './Loading.js';
import Breadcrumbs from './Breadcrumbs.js';
import BehaviorAnalysis from './BehaviorAnalysis.js';
import i18n from './i18n.js';
import y42 from './y42.js';
import soundNewRound from "./../assets/snd/newround.wav";

const Sign = styled(Box)(
  ({ theme }) => ({
    marginRight: "5px",
    marginLeft: 0,
    marginTop: "3px",
    marginBottom: 0,
    borderRadius: "3px",
    width: "16px",
    height: "16px",
    display: "inline-block",
    flexShrink: 0
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} sx={{paddingLeft: 0}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`sit-tabpanel-${index}`}
      aria-labelledby={`sit-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 1}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps2(index) {
  return {
    id: `sit-tab-${index}`,
    'aria-controls': `sit-tabpanel-${index}`
  };
}

export default function RunGame(props) {
  const navigate = useNavigate();
  const pageParams = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [alert, setAlert] = useState({open: false, severity: "info", message: ''});
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [selectedStep, setSelectedStep] = useState(-1);

  const [tab, setTab] = useState(0);
  const [tab2, setTab2] = useState(0);
  const [bigTime, setBigTime] = useState(queryParams.get('bigTime') !== null);

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleChangeTab2 = (event, newTab) => {
    setTab2(newTab);
  };

  const [gs, setGs] = useState({});
  const [situationParams, setSituationParams] = useState({});
  const [situations, setSituations] = useState([]);
  const [situationDescriptions, setSituationDescriptions] = useState([]);
  const [state, setState] = useState({isLoaded : false, isLoading : false, isWaiting : false});
  
  const situationChange = (newSituation, idx, errors) => {
      if ((Array.isArray(errors) && errors.length === 0) || errors === "" || errors === undefined || errors === null) {
        gs.game.situations[idx] = newSituation;
        situations[idx] = newSituation;
        setSituations(gs.game.situations);
      }
  }

  const situationDescChange = (fieldName, newValue) => {
      let idx = fieldName.substring(11);
      gs.game.situationDescriptions[idx] = newValue;
      situationDescriptions[idx] = newValue;
      setSituationDescriptions(situationDescriptions);
  }

  useEffect(() => {
    async function fetch() {
      setState({isLoaded: false, isLoading: true, isWaiting : false});
      const res = await y42.get("games/" + pageParams.id + "/status/initiator");
      if (res._status !== y42.ok)
            setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
      else {
        for (let idx = 0; idx < Object.keys(res.game.situations).length; idx++) {
          res.game.situations[idx] = JSON.parse(res.game.situations[Object.keys(res.game.situations)[idx]]);
        }

        setGs(res);
        setSituations(res.game.situations);
        setSituationDescriptions(res.game.situationDescriptions);
        if (res.playerStatuses.length > 0)
          setSelectedPlayer(res.playerStatuses[0].player);
        setSelectedStep(res.game.currentStep - 1);

        const res1 = await y42.get("games/" + res.game.id + "/situationParams");
        if (res1._status !== y42.ok)
            setAlert({open: true, severity: "error", message: y42.getMessage(res1._status, res1.message)});
        else {
          setSituationParams(res1);
          setState({isLoading : false, isLoaded : true, isWaiting : false})
        }
      }
    }

    if (state.isLoading || state.isLoaded)
      return;

    fetch();
  }, [pageParams.id, state, selectedStep]);



  useEffect(() => {
    // срабатывает при сигнале от сервера
      async function fetch2 () {
        if (state.isLoading) return;
        const res = await y42.get("games/" + pageParams.id + "/status/test");
        if (res._status !== y42.ok) {
          return null;
        } else {
          // закомментировал, чтобы пауза и возобновление были плавнее 
          /*if ((gs.isGameStarted !== res.isGameStarted) ||
              (gs.isGameEnded !== res.isGameEnded) ||
              (gs.game.currentStep !== res.game.currentStep) ||
              (gs.isGamePaused !== res.isGamePaused)) {

              window.location.reload()
          } else {*/
            const res = await y42.get("games/" + pageParams.id + "/status/initiator");
            
            if (res._status !== y42.ok) return null;

            // updating statuses of players: are they online, did they make moves
            for (let idx = 0; idx < Object.keys(res.game.situations).length; idx++) {
              res.game.situations[idx] = JSON.parse(res.game.situations[Object.keys(res.game.situations)[idx]]);
            }
            setGs(res);

            // setSituations(res.game.situations);
            // setSituationDescriptions(res.game.situationDescriptions);
            // if (res.playerStatuses.length > 0)
            //   setSelectedPlayer(res.playerStatuses[0].player);
            // setSelectedStep(res.game.currentStep - 1);

            // const res1 = await y42.get("games/" + res.game.id + "/situationParams");
            // if (res1._status !== y42.ok)
            //     setAlert({open: true, severity: "error", message: y42.getMessage(res1._status, res1.message)});
            // else {
            //   setSituationParams(res1);
            //   setState({isLoading : false, isLoaded : true})
            // }

          // }
        }
      }

      if (gs === undefined || gs.game === undefined) return;
      // console.log("Subscribed to status");
      const client = y42.subscribeWS("/topic/status?gameId=" + gs.game.id, (message) => {
                    // console.log("Received status: ", message.body);
                    fetch2();
                });
      client.activate();

      const client2 = y42.subscribeWS("/topic/active?gameId=" + gs.game.id, (message) => {
                    //console.log("Received user activated: ", message.body);
                    fetch2();
                });
      client2.activate();

      return () => {
        client.deactivate();
        client2.deactivate();
      }
  }, [gs, pageParams.id, state]);

  const runStep = () => {
    setState({isLoaded : true, isLoading : true, isWaiting : true});
    y42.get("games/" + pageParams.id + "/run")
      .then((res) => {
        if (res._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
          setState({isLoaded : false, isLoading : false, isWaiting : false});
        } else
        {
          setAlert({open: true, severity: "info", message: i18n.t("lNextStepSuccessful")});
          setState({isLoaded : false, isLoading : false, isWaiting : false});
        }
      });        
  }

  const stepBack = () => {
    y42.get("games/" + pageParams.id + "/back")
      .then((res) => {
        if (res._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
          setState({isLoaded : false, isLoading : false, isWaiting : false});
        } else
        {
          setAlert({open: true, severity: "info", message: i18n.t("lStepBackSuccessful")});
          // здесь и ниже закомментировал, чтобы не было перезагрузки страницы, т.к. теперь обновление идёт через сигнал через веб-сокеты
          // setState({isLoaded : false, isLoading : false, isWaiting : false});
        }
      });    
  }


  const sendSituation = async () => {
    if (situations == null) {
       setAlert({open: true, severity: "error", message: i18n.t("errThereAreErrorsOnSave")});
       return;
    }

    const res = await y42.post("games/" + pageParams.id + "/sendSituation", "", JSON.stringify(situations));
    if (res._status !== y42.ok) {
        setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
    } else
    {

        const res1 = await y42.post("games/" + pageParams.id + "/sendSituationDescription", "", JSON.stringify(situationDescriptions));
        if (res1._status !== y42.ok) {
            setAlert({open: true, severity: "error", message: y42.getMessage(res1._status, res1.message)});
        } else
        {
            setAlert({open: true, severity: "info", message: i18n.t("lSituationSentSuccessfully")});
            /*for (let idx = 0; idx < Object.keys(res1.situationDescriptions).length; idx++)
              res1.situationDescriptions[idx] = JSON.parse(res1.situationDescriptions[Object.keys(res1.situationDescriptions)[idx]]);*/
            for (let idx = 0; idx < Object.keys(res1.situations).length; idx++) {
              res1.situations[idx] = JSON.parse(res1.situations[Object.keys(res1.situations)[idx]]);
            }
            gs.game = res1;
        }
    }
  }

  const stopGame = () => {
    y42.get("games/" + pageParams.id + "/stop")
      .then((res) => {
        if (res._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
        } else
        {
          setAlert({open: true, severity: "info", message: i18n.t("lGameStoppedSuccessfully")});
          setState({isLoaded : false, isLoading : false, isWaiting : false});
        }
      });    
  }

  const startGame = () => {
    y42.get("games/" + pageParams.id + "/start")
      .then((res) => {
        if (res._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
        } else
        {
          setAlert({open: true, severity: "info", message: i18n.t("lGameStartedSuccessfully")});
          setState({isLoaded : false, isLoading : false, isWaiting : false});
        }
      });    
  }

  const pauseGame = () => {
    y42.get("games/" + pageParams.id + "/pause")
      .then((res) => {
        if (res._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
        } else
        {
          setAlert({open: true, severity: "info", message: i18n.t("lGamePausedSuccessfully")});
         // setState({isLoaded : false, isLoading : false, isWaiting : false});
        }
      });    
  }

  const resumeGame = () => {
    y42.get("games/" + pageParams.id + "/resume")
      .then((res) => {
        if (res._status !== y42.ok) {
          setAlert({open: true, severity: "error", message: y42.getMessage(res._status, res.message)});
        } else
        {
          setAlert({open: true, severity: "info", message: i18n.t("lGameResumedSuccessfully")});
         // setState({isLoaded : false, isLoading : false, isWaiting : false});
        }
      });    
  }

  const saveSituationsAsFile = async () => {
      const fileName = gs.game.name;
      const j = {
        format: "Y42 Situations",
        situations : gs.game.situations,
        descriptions: gs.game.situationDescriptions
      };
      const json = JSON.stringify(j);
      const blob = new Blob([json],{type:'application/json'});
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + ".y42s";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setAlert({open: true, severity: "info", message: i18n.t("lSituationsSavedSuccessfully")});
  }

  const loadSituationsFromFile = (event) => {
      if (gs.isGameStarted) {
          setAlert({open: true, severity: "error", message: i18n.t("errCannotLoadSituationsWhenGameStarted")});
          return;
      }

      let json = event.target.result;
      let j = JSON.parse(json);
      if (j && j.format && j.format === "Y42 Situations") {
          for (let i = 0; i < Object.keys(j.situations).length; i++)
            if (typeof j.situations[i] === 'string')
              j.situations[i] = JSON.parse(j.situations[i]);
          gs.game.situations = j.situations;
          gs.game.situationDescriptions = j.descriptions;
          setSituations(gs.game.situations);
          setSituationDescriptions(gs.game.situationDescriptions);
          setAlert({open: true, severity: "info", message: i18n.t("lSituationsLoadedSuccessfully")});
      } else
          setAlert({open: true, severity: "error", message: i18n.t("errIncorrectFileFormat")});        
  }

  const clearSituations = () => {
      if (gs.isGameStarted) {
          setAlert({open: true, severity: "error", message: i18n.t("errCannotClearSituationsWhenGameStarted")});
          return;
      }

      gs.game.situations = [];
      gs.game.situationDescriptions = [];
      setSituations(gs.game.situations);
      setSituationDescriptions(gs.game.situationDescriptions);
      setAlert({open: true, severity: "info", message: i18n.t("lSituationsClearedSuccessfully")});
  }

  const print = () => {
      window.open('/printReport/' + gs.game.id + '/' + selectedPlayer.id + '/' + selectedStep);
  }

  const getActualSitParams = (step) => {
      let res2 = {};
      if (step > 0) {
        let spkeys = Object.keys(situationParams);
        for (let idx = 0; idx < spkeys.length; idx++) {
          if (situationParams[spkeys[idx]].first === undefined || !situationParams[spkeys[idx]].first) 
            res2[spkeys[idx]] = situationParams[spkeys[idx]];
        }
      } else
        res2 = situationParams;
      return res2;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({open: false, severity: "info", message: ''});
  };

  const handleCloseBigTime = () => {
    setBigTime(false);
  }

  var elements = [];

  if (gs && gs.game)
    for (let i = 0; i < gs.game.stepsNumber; i++) {
      elements.push({i: i, sd: situationDescriptions[i], s: situations[i]});
    }

  let btnNextStep = null;

  if (gs.isGameStarted && !gs.isGameEnded)
    btnNextStep = (<Button color="primary" onClick={runStep} disabled={gs.isStepCalculated || gs.isGamePaused}>{i18n.t("bNextStep")}</Button>);

  let btnPauseResume = null;
  if (gs.isGameStarted && !gs.isGameEnded)
    if (!gs.isGamePaused)
      btnPauseResume = (<Button color="primary" onClick={pauseGame} /*disabled={gs.isStepCalculated}*/>{i18n.t("bPauseGame")}</Button>);
    else
      btnPauseResume = (<Button color="primary" onClick={resumeGame} disabled={gs.isStepCalculated}>{i18n.t("bResumeGame")}</Button>);

  let btnStepBack = null;
  if (gs.isGameStarted /*&& !gs.isGameEnded*/ && gs.game.currentStep > 0)
    btnStepBack = (<Button color="primary" onClick={stepBack} disabled={gs.isStepCalculated}>{i18n.t("bStepBack")}</Button>);

  let tblHeader = [];
  let tblHeader2 = [];
  let tblBody = [];
  let chartComponents = [];

  if (gs.game && gs.game.results) {
    if (gs.playerStatuses.length < gs.game.playersNumber) {
      return (<>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <LittleCard
                    label={i18n.t("lGameStatus")} 
                    text={i18n.t("lNoPlayers")}
                    isAccented={true}
                    onClick={() => { navigate("../games/" + pageParams.id + "/players") }}>
                </LittleCard>
              </Grid>
              </>)
    }

    let rslt = [];
    for (let i = 0; i < gs.game.currentStep; i++) {
      tblHeader.push(<TableCell 
                        key={"h1round" + (i + 1)} 
                        colSpan={2}>
                          <Button 
                            size="small"
                            variant={(i === selectedStep)?"contained":"text"}
                            onClick={() => {
                              setSelectedStep(i);
                            }}>
                            {i18n.t("lStep") + (i + 1)}
                          </Button>
                     </TableCell>);
      tblHeader2.push(<React.Fragment key={"fr+" + (i + 1)}><TableCell key={"h2_1round" + (i + 1)}>{i18n.t("lScore")}</TableCell><TableCell key={"h2_2round" + (i + 1)}>{i18n.t("lRating")}</TableCell></React.Fragment>);
      rslt.push(JSON.parse(gs.game.results[i]).results);
    }
    
    for (let j = 0; j < gs.game.playersNumber; j++) {
      let tblRow = [];
      for (let i = 0; i < gs.game.currentStep; i++)
        for (let p = 0; p < gs.game.playersNumber; p++)
          if ((rslt[i].length >= p + 1) && (rslt[i][p].playerId === gs.playerStatuses[j].player.id)) {
            tblRow.push(<TableCell key={"srslt"+i+"_"+p}>{rslt[i][p].score}</TableCell>);
            tblRow.push(<TableCell key={"rrslt"+i+"_"+p}>{rslt[i][p].rating}</TableCell>);
            break;
          }
      
      tblBody.push(<TableRow key={"trpname"+j}>
                      <TableCell key={"pname"+j}>
                          <Button 
                            size="small"
                            variant={(gs.playerStatuses[j].player.id === selectedPlayer.id)?"contained":"text"}
                            onClick={() => {
                              setSelectedPlayer(gs.playerStatuses[j].player);
                            }}>
                            <Sign sx={{marginTop: "-1px", backgroundColor: helper.getColorByIndex(gs.playerStatuses[j].player.id)}} />
                            {gs.playerStatuses[j].player.name}
                          </Button>
                      </TableCell>
                      {tblRow}
                   </TableRow>);
    }
    let cs = gs.game.currentStep - 1;
    if (gs.isGameStarted && cs >= 0) { 
        chartComponents = (<PrintCharts gs={gs} playerId={selectedPlayer.id} step={selectedStep}/>);
    }

  }

  let fileReader = new FileReader();
  fileReader.onload = loadSituationsFromFile;
  let fontFamily = 'Consolas, Helvetica';

  let cntdwn = null;
  let cntdwn2 = null;

  if (gs.isGamePaused || gs.isGameEnded || !gs.isGameStarted) {
    cntdwn = i18n.t("lGamePaused");
    cntdwn2 = <Typography sx={{fontSize: "25vh"}}>{i18n.t("lGamePaused")}</Typography>;
  } else if (gs.isStepCalculated) {
    cntdwn = i18n.t("lStepBeingCalculated");
    cntdwn2 = <Typography sx={{fontSize: "25vh"}}>{i18n.t("lStepBeingCalculated")}</Typography>;
  } else {
    cntdwn = (<Countdown2 
                  gameId={gs.game.id}
                  nullText={i18n.t("lGamePaused")}
                  callback={() => {
                      y42.playSound(soundNewRound);
                      setState({isLoaded : false, isLoading : false, isWaiting : false});
                    }
                  }/>)
    cntdwn2 = (<Countdown2 
                  gameId={gs.game.id}
                  nullText={i18n.t("lGamePaused")}
                  sx={{color: "#FF0000", fontSize: "50vh", fontFamily: fontFamily}}
                />)
  }

  if (!state.isLoaded || state.isWaiting)
    return (<Loading/>);
  else {
    document.title = y42.getSiteName() + " " + gs.game.name;
    return (
      <>
        <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
            <Alert onClose={handleClose} severity={alert.severity}>
              {alert.message}
            </Alert>
        </Snackbar>

        <Dialog
           fullScreen
           open={bigTime}
           onClose={handleCloseBigTime}
           onClick={() => setBigTime(false)}
           sx={{cursor: "pointer"}}
        >
          <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{color:"#777777", fontSize: "5vh", fontFamily: fontFamily}}>
              { i18n.t("lGame") + " " + gs.game.name}
            </Typography>
            <Typography sx={{color:"#777777", fontSize: "5vh", fontFamily: fontFamily}}>
              {(gs.isGameStarted && !gs.isGameEnded)
                   ? (i18n.t("lCurrentStep") + " " + (gs.game.currentStep + 1) + " / " + gs.game.stepsNumber)
                   : (gs.isGameEnded 
                        ? i18n.t("lGameEnded")
                        : i18n.t("lGameNotStarted"))
              }
            </Typography>
            {cntdwn2}
            <Box>
            { 
              gs.playerStatuses.map((playerStatus) => {
                  let bgColor = "#AFAFAF";
                  let color = "#AFAFAF";
                  if (playerStatus.isPlayerStepFinished) {
                    bgColor = helper.getColorByIndex(playerStatus.player.id);
                    color = 'auto';
                  }
                  return (<React.Fragment key={"pl_s0_" + playerStatus.player.id}>
                    <Sign key={"pl_s1_" + playerStatus.player.id} sx={{backgroundColor: bgColor}}/>
                    <Typography key={"pl_s2_" + playerStatus.player.id} sx={{display: 'inline', mr: 6, fontFamily: fontFamily, fontSize: "3vh", color: color}}>
                      {playerStatus.player.name}
                    </Typography>
                  </React.Fragment>);
              }) 
            }

            </Box>
          </Box>
        </Dialog>

        {
        //Timer time={10} interval={10} callback={fetch2}
        }

        <Box>
          <Breadcrumbs recordTitles={[gs.game.name]}/>
          <Box sx={{paddingLeft: 2, paddingTop: 3}}>
            <Typography variant="button">
              {i18n.t("lGame") + ": " + gs.game.name}
            </Typography>
          </Box>
          <Box sx={{paddingLeft: 3, paddingTop: 1}}>
              <Button
                color="secondary" 
                onClick={() => {window.location.reload()}}>
                {i18n.t("bRefresh")}
              </Button>
              { btnNextStep }
              { btnStepBack }
              { btnPauseResume }
              <Button onClick={() => window.open(location.pathname + "?bigTime")}>{i18n.t("bOpenTimer")}</Button>
              <Button
                color={gs.isGameStarted?"secondary":"primary"} 
                onClick={(gs.isGameStarted)?stopGame:startGame}
                >
                {gs.isGameStarted?(gs.isGameEnded?i18n.t("bRestartGame"):i18n.t("bStopGame")):i18n.t("bStartGame")}
              </Button>
          </Box>
          <Grid container direction="row" justify="flex-start" alignItems="center" sx={{pl: 2}}>
            <LittleCard key="gs1" 
              label={i18n.t("lGameStatus")} 
              text={gs.isGameStarted?(gs.isGameEnded?i18n.t("lGameEnded"):(gs.isGamePaused?i18n.t("lGamePaused"):i18n.t("lGameRunning"))):i18n.t("lGameNotStarted")} 
              isAccented={gs.isGameStarted && !gs.isGameEnded}/>
            <LittleCard key="gs2" 
              label={i18n.t("lCurrentStep")} 
              text={(gs.isGameStarted && !gs.isGameEnded)?((gs.game.currentStep + 1) + "/ " + gs.game.stepsNumber):
                    (gs.isGameEnded?i18n.t("lAllStepsDone") + ": " + gs.game.stepsNumber:i18n.t("lGameNotStarted"))}/>
            <LittleCard key="gs3" 
                label={i18n.t("lStepTimeLeft")}
                onClick={(event) => { 
                    if (event.ctrlKey)
                      window.open(location.pathname + "?bigTime");
                    else
                      setBigTime(true); }}>
                {cntdwn}
            </LittleCard>
            <LittleCard key="gs5" 
                label={i18n.t("lGameVariant")} 
                text={gs.game.variant === null || gs.game.variant==="" ? "—" : gs.game.variant}/>
          </Grid>          
          <Box sx={{paddingLeft: 2, paddingTop: 4}}>
            <Typography variant="button">
            {i18n.t("bPlayers")}
            </Typography>
          </Box>
          <Grid key="pl" container direction="row" justify="flex-start" alignItems="center" sx={{pl: 2}}>
            { 
              gs.playerStatuses.map((playerStatus) => {
                const div = [<Sign 
                              key={"pl_s_" + playerStatus.player.id} 
                              sx={{
                                    backgroundColor: playerStatus.isPlayerLoggedIn ? 
                                                        helper.getColorByIndex(playerStatus.player.id) :
                                                        "#AFAFAF"
                                }}/>, 
                              playerStatus.player.name];
                return (<LittleCard key={"pl" + playerStatus.player.id}
                                    label={div} 
                                    text={playerStatus.isPlayerStepFinished || gs.isGameEnded?i18n.t("lPlayerDone"):i18n.t("lPlayerThinking")}
                                    subText={(playerStatus.player.profile === "" ||  playerStatus.player.profile === null ? "" : playerStatus.player.profile)}
                                    isAccented={!playerStatus.isPlayerStepFinished && !gs.isGameEnded} />);
              }) 
            }


          </Grid>
          <Box sx={{paddingLeft: 1, paddingTop: 4}}>
            <Tabs 
              key="tabs" 
              value={tab} 
              onChange={handleChangeTab} 
              variant="standard"          
              >
              <Tab key="situation" label={i18n.t("lSituation")} {...a11yProps(0)} sx={{pl: 0, pr: 0, mr: 0, ml: 0, minWidth: 0}}/>
              <Tab key="results" label={i18n.t("lResults")} {...a11yProps(1)} sx={{pl: 0, pr: 0, ml: 2, minWidth: 0}}/>
              <Tab key="stats" label={i18n.t("lStats")} {...a11yProps(2)} sx={{pl: 0, pr: 0, ml: 2, minWidth: 0}}/>
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
              <Box sx={{paddingLeft: 3, paddingTop: 0}}>
                <Button key="us" sx={{mr: 2}} color="secondary" onClick={sendSituation}>{i18n.t("bUpdateToServer")}</Button>
                <Button key="ss" sx={{mr: 2}}color="secondary" onClick={saveSituationsAsFile}>{i18n.t("bExportToFile")}</Button>
                <FilePicker
                  onChange={file => {
                     fileReader.readAsText(file);
                  }}
                  onError={err => console.log(err)}
                  style={{display: "inline"}}
                  extensions={["y42s"]}>
                    <Button key="ls" sx={{mr: 2}} color="secondary">
                        {i18n.t("bLoadFromFile")}
                    </Button>
                </FilePicker>
                <Button key="cs" color="secondary" onClick={clearSituations}>{i18n.t("bClearSituations")}</Button>
              </Box>
              <Grid container sx={{mt: 2, pl: 4}}>
                  <Box>
                  <Grid item xs="auto" sm="auto" md="auto" lg="auto">
                      <Tabs 
                        key="tabs2"
                        orientation="horizontal" 
                        value={tab2} 
                        onChange={handleChangeTab2} 
                        indicatorColor="primary"
                        centered={false}
                        sx={{ mt: 1 }}
                      >
                      { elements.map((e) => {return (
                            <Tab key={"sittab"+e.i} {...a11yProps2(2)} 
                                 label={i18n.t("lStep") + " " + (e.i + 1)}
                                 sx={{ ml: 0, mr: 2, pr: 0, pl: 0, minWidth: 0
                                      //textColor: gs.game.currentStep >= e.i?"secondary":"primary"
                                    }}/>
                          )}
                        ) 
                      }
                      </Tabs>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ml: 0, mt: 1}}>  
                    { elements.map((e) => {return (
                        <TabPanel2 value={tab2} index={e.i} key={"panel2_" + e.i}>

                          <Paper elevation={0} sx={{mt: 2}}>
                            <Typography key={"tp_note" + e.i} variant="body2">
                              {i18n.t("lSituationHint")}
                            </Typography>
                            <CodeEditor 
                              language="txt"
                              id={"inp_sitdesc" + e.i}
                              key={"inp_sitdesc" + e.i}
                              name={"inp_sitdesc" + e.i}
                              label={i18n.t("lDescription")}
                              showLineNumbers={false}
                              value={e.sd}
                              onChange={situationDescChange}
                              saveButtonLabel={i18n.t("bClose")}
                              saveCallback={() => {}}
                              disabled={gs.game.currentStep >= e.i}
                              closeOnSave={true}
                            />
                          </Paper>
                          <MoveForm key={"sp" + e.i} 
                                    index={e.i} 
                                    elements={getActualSitParams(e.i)}
                                    values={e.s} 
                                    disabled={gs.game.currentStep >= e.i}
                                    onChange={situationChange}
                                    getInfluence={false}/>
                          </TabPanel2>
                      )
                      }) 
                    }
                  </Grid>
                </Box>
              </Grid>
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <Box sx={{paddingLeft: 3}}>
              <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell rowSpan={2}>{i18n.t("tPlayer")}</TableCell>
                        { tblHeader }
                      </TableRow>
                      <TableRow>
                        { tblHeader2 }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        { tblBody }
                    </TableBody>
                </Table>
              </TableContainer>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <LittleCard 
                    label = {i18n.t("lSelectedPlayer")}
                    text = {selectedPlayer.name}
                    style = {{marginLeft: 0}}
                  />
                <LittleCard 
                    label = {i18n.t("lSelectedStep")}
                    text = {((selectedStep + 1) < 0?i18n.t("lGameNotStarted"):(selectedStep + 1))}
                  />
                <Button 
                  variant="contained"
                  color="primary" 
                  onClick={print}>
                  {i18n.t("bPrint")}
                </Button>
              </Grid>

              {chartComponents}
            </Box>
          </TabPanel> 
          <TabPanel value={tab} index={2}>
             <BehaviorAnalysis gs={gs}/>
          </TabPanel>
        </Box>
      </>);
    
  }
}