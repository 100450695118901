import React, { useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import { TextField, Box, Typography, Grid, Switch, Paper, Alert, Slider } from '@mui/material';
import TextFieldNumber from './TextFieldNumber.js';
import MoveEffectDashboard from './MoveEffectDashboard.js';
import i18n from './i18n.js';
import y42 from './y42.js';
import theme from './../theme.js';

const ItemGrid = styled(Grid)(
  ({ theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }
  }),
);

const LabelGrid = styled(Grid)(
  ({ theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    },
  }),
);

const Hint = styled(Box)(
  ({ theme, accented }) => ({
    display: "inline",
    color: accented?theme.palette.hint.accented:theme.palette.hint.main
  }),
);

const columnRowSx ={
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "#FFFFFF !important",
    },
  };

const columnRowTitleSx = {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTop: "solid 1px #D0D0D0",
    borderBottom: "solid 1px #D0D0D0",
  };

const columnTitleInlineSx = {
  	[theme.breakpoints.up('sm')]: {
      display: "none",
    }, 
    [theme.breakpoints.down('sm')]: {
      display: "inline"
    }
};

export default function MoveForm(props) {
	const [error, setError] = useState("");
	const [errEl, setErrEl] = useState([]);
	const [state, setState] = useState({data: null, elements: null});
	const [displayLimits, setDisplayLimits] = useState([]);
	const [columns, setColumns] = useState({});
	const [rows, setRows] = useState([]);
	const [moveEffect, setMoveEffect] = useState(null);
	const [showMoveEffect, setShowMoveEffect] = useState(false);
	const [alwaysMaxColumns, setAlwaysMaxColumns] = useState(false);
	
	useEffect(() => {
		let newData = props.values?props.values:{};
		let newElements = [];
		let tColumns = {};
		let tGroups = [];
		let tRows = [];

		Object.entries(props.elements).map((element, idx) => {
			if (element[0] === "_status")
				return null;

			if (element[0] === "format") {
				if (element[1]["alwaysMaxColumns"])
					setAlwaysMaxColumns(true);
				return null;
			}

			if (typeof element === 'string')
				element = JSON.parse(element);

			if (!newData[element[0]])
				if (element[1].def !== "undefined")
					newData[element[0]] = element[1].def;
				else
					newData[element[0]] = props.values[element[0]];
			
			if (!element[1].type)
				element[1].type = 'decimal';
			let type = element[1].type.toLowerCase();

			if (type.startsWith('decimal')) {
				let pattern = "^-?\\d+$";
				let p1 = type.indexOf("(");
				if (p1 >= 0) {
					let p2 = type.indexOf(",", p1);
					let p3 = type.indexOf(")", p1);
					let d1 = 0;
					let d2 = 0;
					if (p2 >= p1) {
						d1 = type.substring(p1 + 1, p2);
						d2 = type.substring(p2 + 1, p3);
					}
					else
						d1 = type.substring(p1 + 1, p3);

					pattern = "^-?\\d{1," + d1 + "}";
					if (d2 + 0 > 0)
						pattern = pattern + "\\.?\\d{0," + d2 + "}";
					pattern = pattern + "$";
					element[1].pattern = pattern;
				}
			}

			if (type.startsWith('multistate') || type.startsWith('multi_free')) {
				let p1 = type.indexOf("(");
				if (p1 >= 0) {
					let p3 = type.indexOf(")", p1);
					let arr = type.substring(p1 + 1, p3).split(",");
					let marks = [];
					for (let x = 0; x < arr.length; x++)
						marks.push({value: arr[x] * 1, label: arr[x]});
					element[1].marks = marks;
				}
			}

			let row = "";
			if (element[1].name)
				row = element[1].name;
			let group = "-";
			if (element[1].group)
				group = element[1].group;
			tGroups.push(group);

			let clmn = "";
			if (element[1].column)
				clmn = element[1].column;
			else
				clmn = i18n.t("lGeneral");
			if (!tColumns[group])
				tColumns[group] = [];
			if (!tColumns[group].includes(clmn) && clmn !== "")
				tColumns[group].push(clmn);

			let elRow = {row: row, group: group};
			if (!tRows.find(e => (e.row === elRow.row && e.group === elRow.group)) && element[1].type !== "sum")
				tRows.push(elRow);

			newElements.push(element);
			return element;
		});

    /*for (let i = 0; i < tGroups.length; i++)
			tColumns[tGroups[i]].sort();*/
		setColumns(tColumns);
		setRows(tRows);
    setState({data: newData, elements: newElements});
    checkAllLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values, props.elements])

	const balanceValues = (limitElement, changedElement, newValue, isInternal) => {
		let elements = state.elements;
		let objects = [];
		let sum = 0;
		let data = state.data;
		data[changedElement] = newValue;
		let limitIndex = -1;
		let errStr = "";
		for (let i = 0; i < elements.length; i++) {
			if (elements[i][1].limit === limitElement) {
				objects.push({key: elements[i][0], value : data[elements[i][0]]});
				errStr += elements[i][1].name + ", ";
				sum += data[elements[i][0]] * 1;
			}
			if (elements[i][0] === limitElement)
				limitIndex = i;
		}
		let returnErr = "";
		if (limitIndex >= 0) {
			if (sum > elements[limitIndex][1].limit[1]) {
//				let sum2 = sum - data[changedElement];
//				for (let i = 0; i < objects.length; i++) {
//					if (objects[i].key !== changedElement)
//						data[objects[i].key] = Math.round(objects[i].value / sum * sum2);
//				}
				if (!isInternal)
					setState({data: data, elements: elements});

				if (elements[limitIndex][1].name !== "undefined" && elements[limitIndex][1].name !== null && elements[limitIndex][1].name !== "") 
					errStr = elements[limitIndex][1].name;
				returnErr = errStr + " " + i18n.t("errTogether")  + " " + i18n.t("errCannotBeGreaterThan") + " " + elements[limitIndex][1].limit[1];
			} else if (sum < elements[limitIndex][1].limit[0]) {
//				let lim = elements[limitIndex][1].limit[0];
//				let sum2 = lim - data[changedElement];
//			let sum3 = 0;
//				for (let i = 0; i < objects.length; i++) {
//					if (objects[i].key !== changedElement) {
//				  	data[objects[i].key] = Math.round(objects[i].value / sum * sum2);
//				    sum3 += data[objects[i].key];
//				  }
//				}
//			data[changedElement] = lim - sum3;
				if (!isInternal)
					setState({data: data, elements: elements});

				if (elements[limitIndex][1].name !== "undefined" && elements[limitIndex][1].name !== null && elements[limitIndex][1].name !== "") 
					errStr = elements[limitIndex][1].name;
				returnErr = errStr + " " + i18n.t("errTogether")  + " " + i18n.t("errCannotBeLessThan") + " " + elements[limitIndex][1].limit[0];
			}	

			return {
					err : returnErr, 
					limitLow : elements[limitIndex][1].limit[0], 
					limitHigh: elements[limitIndex][1].limit[1], 
					sum : sum,
					name: elements[limitIndex][1].name
			}
		}

		return {err : "", sum : null, limitLow : null, limitHigh: null};
	}

  const checkLimit = (el, name, value, isInternal) => {
  		let rb = {err : "", sum : null, limitLow : null, limitHigh: null};

  		if (("" + value).match(el.pattern) == null) {
	  		rb.err = "" + el.name + " " + i18n.t("errDoesntMatchFormat");
	  		rb.name = el.name;
  		}
		  else
				if (el && el.limit) {
					let limit = [0, 0];
					if (typeof el.limit === "string") {
						limit = props.elements[el.limit].limit;
						rb = balanceValues(el.limit, name, value, isInternal);

						if (rb.err === "" && value * 1 < 0) {
							rb.err = "" + el.name + " " + i18n.t("errIsNegative");
							rb.name = el.name;
							rb.limitLow = (0);
							rb.limitHigh = (0);
						}

					}	else {
						limit = el.limit;
						if ((limit.length > 1 && value * 1 > limit[1]) || (limit.length > 0 && value * 1 < limit[0])) {
							rb.err = "" + el.name + " " + i18n.t("errExceedsRange") + " ["+ (limit.length>0?limit[0]:"-") + "," + (limit.length>1?limit[1]:"-") + "]";
							rb.name = el.name;
							rb.limitLow = (limit.length>0?limit[0]:null);
							rb.limitHigh = (limit.length>1?limit[1]:null);
						}
					}
				}

			return rb;
  }

  const checkAllLimitsInternal = (isInternal) => {
			let elements = state.elements;
			let errors = [];
			let errelements = [];
			let dilim = [];
			if (elements && elements.length > 0)
				for (let i = 0; i < elements.length; i++) {
					 let el = elements[i];
					 let nm = el[0];

					 let rb = checkLimit(el[1], el[0], state.data[nm], isInternal);
					 if (rb.err !== "") {
					 	  let errfound = false;
					 	  for (let i = 0; i < errors.length; i++) {
					 	  	if (errors[i] === rb.err) {
					 	  		errfound = true;
					 	  		break;
					 	  	}
					 	  }
					 	  if (!errfound)
						 		errors.push(rb.err);
					 		errelements.push(nm);
					 }
					 if (rb.name !== "") {
							let dilimfound = false;
							for (let i = 0; i < dilim.length; i++) {
								if (dilim[i].name === rb.name) {
									dilimfound = true;
									break;
								}
							}
							if (!dilimfound)
								dilim.push(rb);
					 }
				}
				return {erel: errelements, dl: dilim, errors: errors};  	
  }

  const checkAllLimits = () => {
  		let x = checkAllLimitsInternal(false);

			setErrEl(x.erel);
			setDisplayLimits(x.dl);
			return x.errors;
  }

  const getLimit = (el) => {
  	if (el && el.limit) {
					if (typeof el.limit === "string") {
					/*	if (props.elements[el.limit].limit.length > 1)
							if (props.elements[el.limit].limit[0] === props.elements[el.limit].limit[1])
								return i18n.t("lExactly") + " " + props.elements[el.limit].limit[0];
							else
								return i18n.t("lMinimumLimit") + " " + props.elements[el.limit].limit[0] + ", " + i18n.t("lMaximumLimit") + " " + props.elements[el.limit].limit[1];
						else
							return i18n.t("lMinimumLimit") + " " + props.elements[el.limit].limit[0];*/
					}	else {
						if (el.limit.length > 1)
							if (el.limit[0] === el.limit[1])
								return i18n.t("lExactly") + " " + el.limit[0];
							else
								return i18n.t("lMinimumLimitFrom") + " " + el.limit[0] + " " + i18n.t("lMinimumLimitTo") + " " + el.limit[1] + ""; //i18n.t("lMinimumLimit") + " " + el.limit[0] + ", " + i18n.t("lMaximumLimit") + " " + el.limit[1];
						else 
							return i18n.t("lMinimumLimit") + " " + el.limit[0];
					}
		}
		return "";
  }

  const fetch2 = async () => {
  	if (!props.getInfluence) return;
		if (state.data === null) return;
		const res = await y42.post("games/" + props.gameId + "/getMoveInfluence", "", JSON.stringify(state.data));
		if (res._status !== y42.ok)
	  		setMoveEffect(null);
		setMoveEffect(res);
		setShowMoveEffect(false);
	}

	const handleChange = (ev) => {
		let nm = ev.target.name;
		let val = ev.target.value;
		if (ev.target.hasOwnProperty("checked"))
			val = ev.target.checked ? 1 : 0;
		let newData = state.data;
		newData[nm] = val;
		setState({data: newData, elements: state.elements});

		let errors = checkAllLimits();

		props.onChange(state.data, props.index, errors, true);
		fetch2();
		setError(errors);
	}

	const handleChangeNote = (ev) => {
		let nm = ev.target.name;
		let val = ev.target.value;
		let newData = state.data;
		newData[nm] = val;
		setState({data: newData, elements: state.elements});

		props.onChange(state.data, props.index, [], true);
		fetch2();
	}

	const handleChangeSlider = (name, stype, marks) => (ev, value) => {
		let nm = name;
		let val = value;

		if (!stype) { // not free value? (slider but not text with slider)
			// adjust value to the nearest mark
			 let j = 0;
			 let diff = Math.abs(marks[0].value - val);
			 for (let i = 1; i < marks.length; i++) {
			 	  if (Math.abs(marks[i].value - val) < diff) {
			 	  	j = i;
			 	  	diff = Math.abs(marks[i].value - val);
			 	  }
			 }
			 val = marks[j].value;
		}

		let registerAction = (ev.type === "mouseup");

		let newData = state.data;
		newData[nm] = val;
		setState({data: newData, elements: state.elements});

		let errors = checkAllLimits();

		props.onChange(state.data, props.index, errors, registerAction);
		if (registerAction) {
			fetch2();
			setError(errors);
		}
	}

	const handleShowMoveEffectClick = () => {
		setShowMoveEffect(true); 
		props.callbackShowMoveEffects();
	}

	let al = checkAllLimitsInternal(true);
	let er1 = !(((Array.isArray(al.errors) && al.errors.length === 0) || al.errors === "" || al.errors === undefined || al.errors === null));
	if (er1 && props.callBackError)
			props.callBackError();

	let dispLimits = [];
	if (displayLimits && displayLimits.length > 0)
		dispLimits = displayLimits;
	else
		dispLimits = al.dl;

	let dlArray = [];
	dispLimits.map((dl, idx) => {
		if (dl.sum !== null) {
			let dispAmount = dl.limitHigh - dl.sum;
			if (isNaN(dispAmount))
				dispAmount = dl.limitHigh;
			dlArray.push(<React.Fragment key={"lim_"+idx}>
												{dl.name +", " + i18n.t("lRemainingAmount") + ": " + (dispAmount)}<br/>
										</React.Fragment>);
		}
		return null;
	})
	
	let dispErrors = [];
	if (Array.isArray(error) && error.length > 0)
		dispErrors = error;
	else if (al.errors && al.errors.length > 0)
		dispErrors = al.errors;
	else
		dispErrors = error;

	const bgColors = ["#FAFAFA", "#FFFFFF"];
	let curGroup = "";

	let gridSize = props.getInfluence ? 9 : 12;

	let maxColumns = 0;
	let ck = Object.keys(columns);
	if (alwaysMaxColumns) {
		for (let i = 0; i < ck.length; i++) {
			if (columns[ck[i]].length > maxColumns)
				maxColumns = columns[ck[i]].length;
		}
	}

	return (     
    <Grid container direction="row" justify="flex-start">
      <Grid item sx={{maxWidth: theme.breakpoints.values.lg, ml: 1}} xs={12} sm={12} md={gridSize} lg={gridSize} xl={gridSize}>
				<Paper elevation={0}>		
	  			<Box display={dlArray.length===0?"none":"block"} sx={{paddingBottom: 2, paddingTop: 1, position: "sticky", top: "3em", zIndex: "1200"}}>
	  			   <Alert severity="info">
		  				{dlArray}
	  				</Alert>
	  			</Box>
			    <Box display={dispErrors.length===0?"none":"block"} style={{paddingTop: 1, position: "sticky", top: (3 + dlArray.length * 3.5) + "em", zIndex: "1200"}}>
	        		<Alert severity="error">{
	        			Array.isArray(dispErrors)?dispErrors.map((er, idx) => {return <React.Fragment key={"ertxt_"+idx}>{er}<br/></React.Fragment>}):dispErrors
	        		}</Alert>
	      	</Box>
	        	<form noValidate autoComplete="off">
								{	
									(state.elements) &&
									Object.entries(rows).map((rowi, idx) => {
										  let titleGrid = [];
											let rowGrid = [];
											let gridLimit = [];
											let globalComment = "";
											let row = rowi[1].row;
											let group = rowi[1].group;
											let groupItem = "";
											let isNewGroup = false;
											if (curGroup !== group) {
												groupItem = (<Typography key={"group2" + idx} variant="h6" sx={{pt: 4, borderBottom: '1px solid #999999'}}>{group}</Typography>);
												curGroup = group;
												isNewGroup = true;
											}

											let nColumns = columns[group===""?"-":group].length;
											if (alwaysMaxColumns) nColumns = maxColumns;
	                    let clmnsz = Math.floor(8 / nColumns);
	                    if (clmnsz > 3)
	                    	clmnsz = 3;
	                    let fclmnsz = Math.floor(12 - clmnsz * nColumns) / 2;

											Object.entries(columns[group===""?"-":group]).map((clmni, idx2) => {
													let clmn = clmni[1];
													if (isNewGroup) {
															if (nColumns > 1) {
																let item = (
																	<ItemGrid item xs={12} sm={12} md={clmnsz} lg={clmnsz}
																				key={"grd2_" + idx + "_" + idx2 + "_" + props.index}>
								                      <Typography key={"inp2_" + idx + "_" + idx2 + "_" + props.index}>
								                      	{clmn}
								                      </Typography>
								                  </ItemGrid>);
																titleGrid.push(item);
															}
													}; 
													{
														let found = false;
														let sliderMin = 2E63-1;
														let sliderMax = 0;
														Object.entries(state.elements).map((el, idx3) => {
															let element = el[1];
															if (element[1].type.substring(0, 10) === "multistate" || element[1].type.substring(0, 10) === "multi_free") {
																	for (let i = 0; i < element[1].marks.length; i++) {
																		if (element[1].marks[i].value > sliderMax)
																			sliderMax = element[1].marks[i].value;
																		if (element[1].marks[i].value < sliderMin)
																			sliderMin = element[1].marks[i].value;
																	}
															}
															return element;
														});

														Object.entries(state.elements).map((el, idx3) => {
															let element = el[1]; 

															if (element[1].type === "sum")
																return null;
															let elclmn = "";
															if (element[1].column)
																elclmn = element[1].column;
															let item = null;

															if (element[1].name === row)
																if (elclmn === clmn || (elclmn === "" && clmn === i18n.t("lGeneral"))) {
																		let itm = null;
																		if (element[1].type.substring(0, 7) === "decimal")
																				itm = (
													                      <TextFieldNumber
													                        sx={{ml: 0, mr: 0}}
													                        id={"inp_" + element[0] + "_" + props.index}
													                        key={"inp_" + element[0] + "_" + props.index}
													                        name={element[0]}
													                        value={state.data[element[0]]}
													                        inputProps={{
													                        	disabled : props.disabled || element[1].disabled,
													                        	style: { textAlign: "right" }
													                        }}
													                        variant={props.disabled || element[1].disabled ? "filled" : "outlined"}
													                        error={errEl.includes(element[0])}
													                        onChange={handleChange}
													                        display={element[1].visible ? "block" : "none"}
													                        />
																				);
																		if (element[1].type === "boolean")
																				itm = (
													                      <Switch
													                        sx={{ml: 0, mr: 0}}
													                        id={"inp_" + element[0] + "_" + props.index}
													                        key={"inp_" + element[0] + "_" + props.index}
													                        name={element[0]}
													                        checked={(state.data[element[0]]*1 === 1) ? true : false}
													                        disabled = {props.disabled || element[1].disabled}
													                        variant={props.disabled || element[1].disabled ? "filled" : "standard"}
													                        error={errEl.includes(element[0]).toString()}
													                        onChange={handleChange}
													                        display={element[1].visible ? "block" : "none"}
													                        />
																				);
																		if (element[1].type.substring(0, 10) === "multistate" || element[1].type.substring(0, 10) === "multi_free") {
																			  let itm1 = null;
																			  let stype = (element[1].type.substring(0, 10) === "multi_free");
																			  if (stype)
																			  	itm1 = (<TextField
														                        id={"inp_t" + element[0] + "_" + props.index}
														                        key={"inp_t" + element[0] + "_" + props.index}
														                        name={element[0]}
														                        value={state.data[element[0]]}
														                        inputProps={{
														                        	disabled : props.disabled || element[1].disabled,
														                        	style: { textAlign: "right" }
														                        }}
														                        variant={props.disabled || element[1].disabled ? "filled" : "outlined"}
														                        error={errEl.includes(element[0])}
														                        onChange={handleChange}
														                        sx={{ml: 0, mr: 0, width: "16ch", pr : 4}}
													                        />);
																			  let stl = {ml: 0, mr: 0};
																			  if (stype) {
																			  	 stl['width'] = '40%';
																			  }
																			  else {
																	   	   stl['width'] = '80%';
																			   if (element[1].marks.length > 10) {
																			    	stl['& .MuiSlider-markLabel'] = {display: 'none'};
																			    	stl['& .MuiSlider-markLabel[data-index="0"]'] = {display: 'inline'};
																			    	stl['& .MuiSlider-markLabel[data-index="' + (element[1].marks.length - 1) + '"]'] = {display: 'inline'};
																			    }
																			    //console.log(element[1].marks);
																			  }
																				let itm2 = (
													                        <Slider
														                        id={"inp_" + element[0] + "_" + props.index}
														                        key={"inp_" + element[0] + "_" + props.index}
														                        name={element[0]}
														                        value={state.data[element[0]]}
														                        aria-labelledby="discrete-slider-custom"
														                        disabled = {props.disabled || element[1].disabled}
														                        error={errEl.includes(element[0]).toString()}
														                        onChange={handleChangeSlider(element[0], stype, element[1].marks)}
														                        onChangeCommitted={handleChangeSlider(element[0], stype, element[1].marks)}
														                        marks={ element[1].marks }
														                        valueLabelDisplay={'auto'}
														                        min={stype ? sliderMin : element[1].marks[0].value}
														                        max={stype ? sliderMax : element[1].marks[element[1].marks.length - 1].value}
														                        step={ stype ? Math.max(Math.floor((sliderMax - sliderMin) / 1000), 1) : null}
														                        sx={ stl }
													                        />
																					);
																				itm = [itm1, itm2];
																			}
																		if (element[1].type === "note")
																				itm = (
													                      <TextField
													                        sx={{ml: 0, mr: 0, width : "100%"}}
													                        id={"inp_" + element[0] + "_" + props.index}
													                        key={"inp_" + element[0] + "_" + props.index}
													                        name={element[0]}
													                        value={state.data[element[0]]}
													                        multiline={true}
													                        rows={3}
													                        inputProps={{
													                        	disabled : props.disabled || element[1].disabled
													                        }}
													                        variant={props.disabled  || element[1].disabled ? "filled" : "outlined"}
													                        error={errEl.includes(element[0])}
													                        onChange={handleChangeNote}
													                        />

																				);

																		item = (
																			<ItemGrid item xs={12} sm={12} md={clmnsz} lg={clmnsz} key={"grd2_" + idx + "_" + idx2 + "_" + props.index}>
																				<Box key={"grd2_spn_" + idx + "_" + idx2 + "_" + props.index}
																						sx={columnTitleInlineSx}>{(clmn!==""&&clmn!==i18n.t("lGeneral"))?(clmn + ": "):""}&nbsp;</Box>
																				{itm}
																			</ItemGrid>);
																		rowGrid.push(item);
																		gridLimit.push(getLimit(element[1]) + (element[1].comment ? element[1].comment : ""));
																		globalComment += element[1].globalComment ? element[1].globalComment : "";
																		found = true;
																		return null;
																}
																return null;	
															})
															if (!found) {
																let item = (
																		<ItemGrid item xs={12} sm={12} md={clmnsz} lg={clmnsz} key={"nf_"+idx+"_"+idx2+"_"+props.index}>
							                  		</ItemGrid>
							                  );
							                  rowGrid.push(item);
															}
														}

													return null;	
											});

										let glAllEqual = true;
										let glFirst = gridLimit[0];
										if (gridLimit.length > 1)
											for (let glidx = 1; glidx < gridLimit.length; glidx += 1) {
												if (gridLimit[glidx] !==  glFirst) {
														glAllEqual = false;
														break;
												}
											}
										let limitsItem = null;
										if (!glAllEqual) 
											limitsItem = (
													<Grid container 
																key={"nf9_"+idx}
			        									sx={{pt: 0, pb: 1, mt:-1}} 
			        									style={{backgroundColor: bgColors[idx%2]}}>
					        						<LabelGrid item xs={12} sm={12} md={fclmnsz} lg={fclmnsz} key={"nf3_"+idx}>
						        							<Typography></Typography>
						        					</LabelGrid>
						        					{gridLimit.map((gl, idx2) => {
						        									return (<ItemGrid item xs={12} sm={12} md={clmnsz} lg={clmnsz} key={"spn_hint_inp3_" + idx + "_" + idx2}>
						        										         <Hint key={"spn_hint_inp4_" + idx + "_" + idx2}>{gl}<br/></Hint>
						        										      </ItemGrid>);
						        								})}
						        					<ItemGrid item xs={12} sm={12} md={fclmnsz} lg={fclmnsz} key={"nf4_"+idx}>
						                  </ItemGrid>
												  </Grid>
												);

										let titleItem = null;
										if (titleGrid.length > 0)
											titleItem = (
			        						<Grid container 
			        						 			key={"nf8_"+idx}
			        									sx={columnRowTitleSx} 
			        									style={{borderBottom: "1px solid #999999"}}>
					        						<LabelGrid item xs={12} sm={12} md={fclmnsz} lg={fclmnsz} key={"nf5_"+idx}>
						        							<Typography key={"nf7_"+idx}></Typography>
						        					</LabelGrid>
						        					{titleGrid}
						        					<ItemGrid item xs={12} sm={12} md={fclmnsz} lg={fclmnsz} key={"nf6_"+idx}>
						                  </ItemGrid>
												  </Grid>
											);

										return (rowGrid.length === 0 ? null :
												<React.Fragment key={"div_inp9_" + idx}>
													{groupItem}
													{titleItem}
			        						<Grid container 
			        									sx={columnRowSx} 
			        									style={{backgroundColor: bgColors[idx%2]}}>
					        						<LabelGrid item xs={12} sm={12} md={fclmnsz} lg={fclmnsz}>
						        							<Typography>{row}</Typography>
						        					</LabelGrid>
						        					{rowGrid}
						        					<ItemGrid item xs={12} sm={12} md={fclmnsz} lg={fclmnsz} >
						        							{glAllEqual ? (<Hint key={"spn_hint_inp5_" + idx}>{glFirst}</Hint>) : null}
						        							{globalComment !== "" ? (<Hint key={"spn_hint_inp5_" + idx}>{globalComment}</Hint>) : null}
						                  </ItemGrid>
												  </Grid>
												  {limitsItem}
					             	</React.Fragment>
										);
									})
								}
				    </form>
	    	</Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{pl: 2}}>
         <Grid container direction="row" justify="flex-start" alignItems="left" 
               sx={{[theme.breakpoints.down('sm')]: {pt: 2}}}>
           <Box sx={{filter: showMoveEffect ? "none": "blur(10px)"}} onClick={handleShowMoveEffectClick}>
	           <MoveEffectDashboard 
	           		gameId={props.gameId}
	           		move={state.data}
	           		moveEffect={moveEffect}/>
           	</Box>
         </Grid>
      </Grid>
   </Grid>
  );
}
