import React, { useState } from 'react';
import { TextField } from '@mui/material'; // Импорт стандартного TextField из MUI

export default function TextFieldNumber (props) {
  const [value, setValue] = useState(props.value); // Значение текстового поля
  const [isEditing, setIsEditing] = useState(false); // Флаг состояния редактирования

  // Функция форматирования числа с пробелами
  const formatNumber = (num) => {
    return (num + "").replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  // Функция обработки изменения значения
  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/\s/g, ''); // Удаляем пробелы
    if (/^\d*$/.test(inputValue)) { // Проверяем, что вводятся только цифры
      setValue(inputValue);
      if (props.onChange) props.onChange(e);
    }
  };

  return (
    <TextField
      {...props} // Передаем все свойства, полученные через props
      value={isEditing ? value : formatNumber(value)} // Форматируем значение, если не редактируем
      onChange={handleChange} // Переопределяем onChange
      onFocus={(e) => {
        setIsEditing(true);
        if (props.onFocus) props.onFocus(e); // Вызываем переданный onFocus, если есть
      }}
      onBlur={(e) => {
        setIsEditing(false);
        if (props.onBlur) props.onBlur(e); // Вызываем переданный onBlur, если есть
      }}
    />
  );
};

